import {
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFormRow,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Hand } from "store/data/scan/scan";
import { MMOrderLineFieldProps } from "../order-line-entry";

function MMOrderHand(props: MMOrderLineFieldProps) {
  const [hand, setHand] = useState<Hand | null>(
    props.selection && props.selection.hand ? props.selection.hand : null
  );
  const [enabledHands, setEnabledHands] = useState<string[]>([]);

  useEffect(() => {
    setEnabledHands(
      props.selection.variation && props.selection.variation.hand
        ? props.selection.variation.hand
        : []
    );

    setHand(
      props.selection && props.selection.hand ? props.selection.hand : null
    );
  }, [props.selection]);

  const onHandChange = (hand: Hand | null) => {
    if (props.onChange) {
      props.onChange({ ...props.selection, hand: hand ? hand : undefined });
    } else {
      setHand(hand ? hand : null);
    }
  };

  return (
    <EuiFlexGrid gutterSize="none">
      <EuiFlexGroup gutterSize="xs">
        <EuiFormRow
          display="rowCompressed"
          className="product-input"
          label={props.showTitle ? txt.get("orders.order.hand") : undefined}
        >
          <EuiFilterGroup contentEditable={false} compressed={true}>
            <EuiFilterButton
              isDisabled={
                !props.isEditable || !enabledHands.includes(Hand.Left.charAt(0))
              }
              style={{
                minWidth: "26px",
                padding: "0px",
                margin: "auto",
              }}
              hasActiveFilters={hand === Hand.Left}
              onClick={(e: any) =>
                onHandChange(hand === Hand.Left ? null : Hand.Left)
              }
            >
              {txt.get("scanning.scans.scan.hand.left")}
            </EuiFilterButton>
            <EuiFilterButton
              isDisabled={
                !props.isEditable ||
                !enabledHands.includes(Hand.Right.charAt(0))
              }
              style={{
                minWidth: "26px",
                padding: "0px",
                margin: "auto",
              }}
              hasActiveFilters={hand === Hand.Right}
              onClick={(e: any) =>
                onHandChange(hand === Hand.Right ? null : Hand.Right)
              }
            >
              {txt.get("scanning.scans.scan.hand.right")}
            </EuiFilterButton>
          </EuiFilterGroup>
        </EuiFormRow>
      </EuiFlexGroup>
    </EuiFlexGrid>
  );
}

export default MMOrderHand;
