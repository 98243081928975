import {
  EuiFlexGrid,
  EuiIcon,
  EuiImage,
  EuiLoadingSpinner,
  EuiModal,
} from "@elastic/eui";
import ManoXAPIHelper from "api/manox-api-helper";
import MMPdfPreview from "components/orders/pdf/pdf-preview";
import MMPdfImagePreview from "components/orders/pdf/pdf-preview-image";
import { deviceIsIOS } from "helpers/device-helper";
import FileUploadHelper from "helpers/file-upload-helper";
import { Fragment, useEffect, useState } from "react";

interface MMSecureImageProps {
  url: string;
  thumbnailUrl?: string;
  openInNewWindow?: boolean;
  allowFullScreen?: boolean;
  mimeType?: string;
  api?: any;
  filename?: string;
  alt?: string;
  caption?: string;
  showScanOverlay?: boolean;
  detailedPreview?: boolean;
}

function MMSecureImage(props: MMSecureImageProps) {
  const [imageThumbSrc, setImageThumbSrc] = useState<string | undefined>();
  const [imageSrc, setImageSrc] = useState<string | undefined>();
  const [imageWidth, setImageWidth] = useState<number | undefined>();
  const [imageHeight, setImageHeight] = useState<number | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDetailedPreviewOpen, setIsDetailedPreviewOpen] =
    useState<boolean>(false);
  const api = props.api || new ManoXAPIHelper();

  useEffect(() => {
    const loadImageSrc = async () => {
      // console.log("loadImageSrc", props.url);
      const thumb = await api.getAssetByUrl(
        props.thumbnailUrl || props.url,
        false
      );

      if (thumb) {
        setImageThumbSrc(URL.createObjectURL(thumb));
      }

      setIsLoading(false);

      const image = await api.getAssetByUrl(props.url, false);
      const source = URL.createObjectURL(image);
      let imageElement = document.createElement("img");
      imageElement.onload = () => {
        setImageWidth(imageElement.width);
        setImageHeight(imageElement.height);
      };
      imageElement.src = source;
      setImageSrc(source);
    };

    loadImageSrc();
  }, [props.url]);

  const renderDetailedPreview = () =>
    isDetailedPreviewOpen ? (
      <EuiModal
        style={{
          backgroundColor: "#fffffff",
          maxWidth: "90vw",
          maxHeight: "80vh",
          width: "90vw",
          height: "90vh",
        }}
        onClose={(e) => setIsDetailedPreviewOpen(false)}
        initialFocus="[name=popswitch]"
        className="image-preview"
      >
        {props.mimeType ? (
          ["application/pdf"].includes(props.mimeType) ? (
            <MMPdfPreview file={imageSrc} />
          ) : imageSrc && imageWidth && imageHeight ? (
            <MMPdfImagePreview
              file={imageSrc}
              width={imageWidth}
              height={imageHeight}
            />
          ) : (
            <>loading image</>
          )
        ) : (
          <>no image</>
        )}
      </EuiModal>
    ) : (
      <></>
    );

  const filename = (): string => {
    if (props.filename) {
      return props.filename;
    } else if (!props.filename && props.mimeType) {
      let parts: string[] = props.mimeType.split("/");
      if (parts.length > 1) return `download.${parts[1]}`;
    }
    return "download";
  };

  return isLoading ? (
    <EuiFlexGrid alignItems="center">
      <EuiLoadingSpinner size="l" />
    </EuiFlexGrid>
  ) : imageThumbSrc ? (
    <Fragment>
      <EuiImage
        wrapperProps={{
          id:
            props.showScanOverlay === undefined ||
            props.showScanOverlay === true
              ? "scan-position-overlay"
              : "",
        }}
        className={
          props.showScanOverlay === undefined || props.showScanOverlay === true
            ? "image scan-position-overlay"
            : "image "
        }
        style={
          props.openInNewWindow && imageSrc ? { cursor: "pointer" } : undefined
        }
        onClick={
          props.detailedPreview &&
          props.mimeType &&
          !deviceIsIOS() &&
          [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "image/gif",
            "image/bmp",
            "image/webm",
            "application/pdf",
          ].includes(props.mimeType)
            ? (e) => {
                setIsDetailedPreviewOpen(true);
              }
            : props.openInNewWindow
              ? (e) => {
                  if (imageSrc) {
                    FileUploadHelper.triggerDownloadFile(imageSrc, filename());
                  }
                }
              : undefined
        }
        allowFullScreen={
          !deviceIsIOS() && props.detailedPreview
            ? false
            : props.allowFullScreen !== undefined
              ? props.allowFullScreen && !props.openInNewWindow
              : !props.openInNewWindow
        }
        caption={props.caption ?? ""}
        alt={props.alt ?? "Image"}
        // src={`data:${props.mimeType || "image/jpeg"};base64, ${imageSrc}`}
        src={!props.openInNewWindow && imageSrc ? imageSrc : imageThumbSrc}
      />
      {renderDetailedPreview()}
    </Fragment>
  ) : (
    <EuiIcon color="#ccc" size="xxl" type="image" />
  );
}

export default MMSecureImage;
