import { EuiFlexGrid, EuiFlexItem } from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ClientsAPIHelper from "api/clients-api.helper";
import AuthenticationHelper from "helpers/authentication-helper";
import { useEffect, useState } from "react";
import { Appointment } from "store/data/appointment/appointment";
import { Client } from "store/data/client/client";
import MMAppointmentInfo from "./appointment-info";
import MMContactMomentInfo from "./contact-moment-info";

export interface MMCommunicationInfoProps {
  client: Client | null;
}

function MMCommunicationInfo(props: MMCommunicationInfoProps) {
  const clientApi: ClientsAPIHelper = new ClientsAPIHelper();

  const [client, setClient] = useState<Client>();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [contactMoments, setContactMoments] = useState<any[]>([]);

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
  }, [props.client]);

  useEffect(() => {
    const loadAppointmentInfo = async (client: Client, referralId?: number) => {
      if (await AuthenticationHelper.hasPermission(["appointments#read_all"])) {
        let filters: any = {};
        filters.vlot_client_id = client.vlot_id;
        const appointmentsResult: ApiResponse =
          await clientApi.getAppointments(filters);
        if (
          appointmentsResult &&
          appointmentsResult.status === ApiResponseStatus.OK
        ) {
          setAppointments(appointmentsResult.result);
        } else {
          console.log("appointmentsResult error", appointmentsResult.message);
          setAppointments([]);
        }
      }
    };

    const loadContactMomentInfo = async (
      client: Client,
      referralId?: number
    ) => {
      if (await AuthenticationHelper.hasPermission(["appointments#read_all"])) {
        let filters: any = {};
        if (referralId) {
          filters.referral_ids = [referralId];
        }
        if (client.vlot_id) {
          filters.vlot_client_id = client.vlot_id;
          const contactMomentResult: ApiResponse =
            await clientApi.getClientContactMoments(filters);
          if (
            contactMomentResult &&
            contactMomentResult.status === ApiResponseStatus.OK
          ) {
            setContactMoments(contactMomentResult.result);
          } else {
            console.log(
              "contactMomentResult error",
              contactMomentResult.message
            );
            setContactMoments([]);
          }
        } else {
          setContactMoments([]);
        }
      }
    };

    if (client) {
      loadAppointmentInfo(client);
      loadContactMomentInfo(client);
    } else {
      setAppointments([]);
      setContactMoments([]);
    }
  }, [client]);

  return client ? (
    <EuiFlexGrid className="subtle-editing">
      {appointments ? (
        <EuiFlexItem>
          <MMAppointmentInfo client={client} appointments={appointments} />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {contactMoments ? (
        <EuiFlexItem>
          <MMContactMomentInfo
            client={client}
            contactMoments={contactMoments}
          />
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMCommunicationInfo;
