import {
  EuiButtonIcon,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ClientsAPIHelper from "api/clients-api.helper";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";

export interface MMInsuranceInfoProps {
  client: Client | null;
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMInsuranceInfo(props: MMInsuranceInfoProps) {
  const clientApi: ClientsAPIHelper = new ClientsAPIHelper();

  const [client, setClient] = useState<Client>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isHeaderShown] = useState<boolean>(false);
  const [insurance, setInsurance] = useState<any>();

  useEffect(() => {
    const loadInsuranceDetails = async (client: Client) => {
      if (client.id) {
        const insuranceResult: ApiResponse =
          await clientApi.getInsuranceDetails(client.id);
        if (
          insuranceResult &&
          insuranceResult.status === ApiResponseStatus.OK
        ) {
          setInsurance(insuranceResult.result);
        } else {
          console.log("Something went wrong, no client?", insuranceResult);
        }
      }
    };

    if (client) {
      loadInsuranceDetails(client);
    }
  }, [client]);

  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
  }, [props.client, props.isEditable, props.isHeaderShown]);

  const toInsuranceDetails = (insurance: any) => {
    let result: string = "";
    if (insurance) {
      result += `${insurance.insurer_name} (${insurance.insurer_uzovi_code})`;

      result += `, ${insurance.insurance_name}`;
    }
    return result;
  };
  return client ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem>
        <EuiFlexGroup justifyContent="spaceBetween">
          <MMTitle text={txt.get("clients.insurance_info.name")} />
          <EuiButtonIcon aria-label="refresh" iconType={"refresh"} />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <EuiFlexGroup
            gutterSize="xs"
            style={{ maxWidth: "400px" }}
            alignItems="center"
          >
            <EuiFlexItem grow={8}>
              <EuiFormRow
                display="rowCompressed"
                label={
                  isHeaderShown
                    ? txt.get("clients.insurance_info.details")
                    : undefined
                }
                title={txt.get("clients.insurance_info.details")}
              >
                <EuiTextArea
                  rows={2}
                  placeholder={txt.get("clients.insurance_info.details")}
                  compressed={true}
                  disabled={!isEditable}
                  name="insurance_details"
                  value={insurance ? toInsuranceDetails(insurance) : ""}
                  onChange={(e) => {}}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={3}>
              {insurance ? (
                <EuiText size="xs" textAlign="right" color="subdued">
                  {txt.get("clients.insurance_info.reference_date")}
                  <br />
                  {`${DateHelper.toDate(insurance.reference_date)}`}
                </EuiText>
              ) : (
                <></>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMInsuranceInfo;
