import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { isDefaultStatusLogComment, Order } from "store/data/order/order";
import {
  referralStatusDescription,
  ReferralStatusLog,
} from "store/data/referral/referral";

export interface MMReferralStatusLogsProps {
  order?: Order | null;
}

function MMReferralStatusLogs(props: MMReferralStatusLogsProps) {
  const api = new ConnectAPIHelper();

  const [logs, setLogs] = useState<ReferralStatusLog[]>([]);
  const [order, setOrder] = useState(props.order);

  const loadLogs = async () => {
    const canSeeLogs: boolean = await AuthenticationHelper.hasPermission([
      "referrals#accept_all",
      "referrals#accept_external",
    ]);
    console.log("loadLogs", canSeeLogs);
    if (canSeeLogs && order && order.id) {
      const result: ReferralStatusLog[] = await api.getOrderReferralStatusLogs(
        order.id
      );
      setLogs(result || []);
    } else {
      setLogs([]);
    }
  };

  useEffect(() => {
    loadLogs();
  }, []);

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);
  const columns = () => [
    {
      name: "", //`${txt.get("generic.log")}`,
      truncateText: false,
      //   width: "250px",
      render: (log: any) => (
        <EuiFlexGroup direction="column" gutterSize="xs">
          {!isDefaultStatusLogComment(log.comment) ? (
            <EuiFlexItem>
              <EuiText size="xs">
                <i>{log.comment}</i>
              </EuiText>
            </EuiFlexItem>
          ) : (
            <></>
          )}
          <EuiFlexItem>
            <EuiText size="xs">
              {DateHelper.ago(log.logged_at, txt.lang(), true)}&nbsp;
              {referralStatusDescription(log.new_status).toLowerCase()}&nbsp;
              {txt.lo("generic.by_x", log.user)}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
  ];

  return logs && logs.length > 0 ? (
    <EuiFlexItem style={{ maxWidth: "250px", backgroundColor: "transparent" }}>
      <EuiBasicTable
        className="table-transparent table-no-header"
        itemId="id"
        items={logs}
        columns={columns()}
        noItemsMessage={txt.uf("generic.found_no_x", txt.lo("generic.log"))}
      />
    </EuiFlexItem>
  ) : (
    <></>
  );
}

export default MMReferralStatusLogs;
