import txt from "helpers/text-helper";

//todo, add product stuff here, instead of mixed in orders / scans (like hand etc)
export enum ProductUse {
  Night = "night",
  Day = "day",
}

export const productUseDescription = (
  productUse: ProductUse,
  translationBase: string = "orders.order.use_option"
) => {
  return txt.uc(`${translationBase}.${productUse.toString().toLowerCase()}`);
};

export const productNameMain = (product: any) => {
  if (!product.label) return "";
  const label: string = product.label;
  const parts: string[] = label.split(", ");
  const main: string =
    parts.length === 1 && parts[0].indexOf("(") > 0
      ? parts[0].substring(0, parts[0].indexOf("(") - 1)
      : parts.length <= 2
        ? parts[0]
        : parts.length > 2
          ? parts.slice(0, 2).join(", ")
          : "";
  return main;
};

export const productNameSub = (product: any) => {
  if (!product.label) return "";
  let sub: string = product.label.replace(productNameMain(product), "");

  if (sub.indexOf(", ") === 0) {
    sub = sub.substring(2);
  }
  sub = sub.replace("(", "").replace(")", "");
  return sub;
};

export const toFunctionDescriptionList = (functionDescription: string) =>
  functionDescription.trim().split(/\s*[\r\n]+\s*/g);
