import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLoadingSpinner,
  EuiPanel,
  EuiProgress,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import MMPage from "components/layouts/page/page";

import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import env from "helpers/env-helper";
import FileUploadHelper from "helpers/file-upload-helper";
import { selectableProductOptions } from "helpers/product-helper";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";
import { Organisation } from "store/data/organisation/organisation";
import { BigPlayButton, Player } from "video-react";
import MMProductCards from "./product-cards";

// const FILE_PORTFOLIO_THUMB = env("REACT_APP_FILE_PORTFOLIO_THUMB");
const FILE_PORTFOLIO = env("REACT_APP_FILE_PORTFOLIO");
const FILE_PORTFOLIO_DE = env("REACT_APP_FILE_PORTFOLIO_DE");
const FILE_PRICES = env("REACT_APP_FILE_PRICES");
const FILE_TOC = env("REACT_APP_FILE_TOC");
const FILE_VIDEO_MEASUREMENTS = env("REACT_APP_FILE_VIDEO_MEASUREMENTS");
const FILE_VIDEO_ADJUSTMENTS = env("REACT_APP_FILE_VIDEO_ADJUSTMENTS");

function MMProducts() {
  const [isDownloading, setIsDownloading] = useState(false);
  const [videoMeasurements, setVideoMeasurements] = useState("");
  const [videoAdjustments, setVideoAdjustments] = useState("");

  const [filePrices, setFilePrices] = useState("");

  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const lang: string = txt.lang();

  const [products, setProducts] = useState<any[]>([]);

  const fileUploadHelper = new FileUploadHelper();

  useEffect(() => {
    const loadPricelist = async () => {
      const organisation: Organisation = await api.getOrganisation(
        AuthenticationHelper.getOrganisationId()
      );
      if (organisation && organisation.pricelist) {
        setFilePrices(organisation.pricelist.url);
      } else {
        setFilePrices(FILE_PRICES);
      }
    };

    const loadVideos = async () => {
      setVideoMeasurements(
        await fileUploadHelper.downloadFileUrl(FILE_VIDEO_MEASUREMENTS)
      );
      setVideoAdjustments(
        await fileUploadHelper.downloadFileUrl(FILE_VIDEO_ADJUSTMENTS)
      );
    };
    const loadProducts = async () => {
      const products: any[] = await api.getProducts();
      console.log("loaded products", products.length);
      setProducts(selectableProductOptions(products || [], lang));
    };
    loadVideos();
    loadProducts();
    loadPricelist();
  }, []);

  const triggerDownload = async (url: string) => {
    let filename: any = url.split("/");
    filename = filename.length > 0 ? filename[filename.length - 1] : "download";
    setIsDownloading(true);
    await fileUploadHelper.triggerDownloadFileUrl(url, filename);
    setIsDownloading(false);
  };

  return (
    <MMPage title={txt.get("products.portfolio.page_title")}>
      {isDownloading ? (
        <EuiProgress size="xs" color="accent" />
      ) : (
        <Fragment>
          <EuiSpacer size="xs" style={{ height: "2px" }} />
        </Fragment>
      )}
      <EuiSpacer size="xs" />
      <EuiFlexGroup style={isDownloading ? { cursor: "wait" } : {}}>
        <EuiFlexItem grow={4} style={{ alignSelf: "flex-start" }}>
          <MMProductCards api={api} products={products} />
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiSpacer size="xxl" />
          <EuiSpacer size="xl" />
          <EuiFlexGroup direction="column">
            <EuiFlexItem grow={false}>
              <EuiFlexGroup>
                <EuiFlexItem grow={1}>
                  <EuiPanel>
                    <EuiImage
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        triggerDownload(FILE_PORTFOLIO);
                      }}
                      size={"xl"}
                      alt="fingersplints portfolio"
                      src="/images/fingersplints_3.0.png"
                    />

                    <EuiSpacer size="s" />
                    <EuiFlexGroup
                      justifyContent="spaceBetween"
                      direction="column"
                      gutterSize="s"
                    >
                      <EuiButton
                        fill={false}
                        iconSide="right"
                        iconType={"download"}
                        onClick={() => {
                          triggerDownload(FILE_PORTFOLIO);
                        }}
                      >
                        {txt.get("generic.download")} (EN)
                      </EuiButton>
                      <EuiButton
                        fill={false}
                        iconSide="right"
                        iconType={"download"}
                        onClick={() => {
                          triggerDownload(FILE_PORTFOLIO_DE);
                        }}
                      >
                        {txt.get("generic.download")} (DE)
                      </EuiButton>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem grow={1}>
                  <EuiPanel>
                    <EuiFlexGroup
                      direction="column"
                      justifyContent="spaceBetween"
                      style={{
                        height: "100%",
                      }}
                    >
                      <EuiFlexItem>
                        <EuiText>
                          <h3>Pricelist 2023</h3>
                        </EuiText>

                        <EuiText>(EU, UK & CH)</EuiText>
                        <EuiSpacer />
                        <EuiText>
                          The general terms and conditions of Manometric apply.
                          No rights can be derived from this price list.
                        </EuiText>
                      </EuiFlexItem>

                      <EuiFlexItem
                        style={{
                          justifySelf: "flex-end",
                          alignContent: "flex-end",
                        }}
                      >
                        <EuiFlexGroup
                          direction="column"
                          gutterSize="s"
                          justifyContent="flexEnd"
                        >
                          <EuiButton
                            onClick={() => {
                              if (filePrices) triggerDownload(filePrices);
                            }}
                            iconSide="right"
                            iconType="download"
                          >
                            Download Pricelist 2023
                          </EuiButton>
                          <EuiButton
                            onClick={() => {
                              triggerDownload(FILE_TOC);
                            }}
                            iconSide="right"
                            iconType="download"
                          >
                            Download T&C
                          </EuiButton>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiPanel style={{ minWidth: "551px", maxWidth: "650px" }}>
                {videoMeasurements ? (
                  <Player poster="/video/swanneck.png">
                    {videoMeasurements ? (
                      <source src={videoMeasurements} />
                    ) : (
                      <EuiLoadingSpinner />
                    )}
                    <BigPlayButton position="center" />
                  </Player>
                ) : (
                  <EuiFlexItem
                    grow={false}
                    style={{ width: "516px", position: "relative" }}
                  >
                    <EuiLoadingSpinner
                      size="l"
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        left: "calc(50% - 13.5px)",
                        top: "15%",
                      }}
                    />
                    <EuiImage
                      alt="swanneck instructions"
                      src="/video/swanneck.png"
                    />
                  </EuiFlexItem>
                )}
              </EuiPanel>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiPanel style={{ minWidth: "551px", maxWidth: "650px" }}>
                {videoAdjustments ? (
                  <Player width={650} poster="/video/double-swanneck.png">
                    <BigPlayButton position="center" />
                    {videoAdjustments ? (
                      <source src={videoAdjustments} />
                    ) : null}
                  </Player>
                ) : (
                  <EuiFlexItem style={{ width: "516px", position: "relative" }}>
                    <EuiLoadingSpinner
                      size="l"
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        left: "calc(50% - 13.5px)",
                        top: "15%",
                      }}
                    />
                    <EuiImage
                      alt="swanneck instructions"
                      src="/video/double-swanneck.png"
                    />
                  </EuiFlexItem>
                )}
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </MMPage>
  );
}

export default MMProducts;
