import {
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
} from "@elastic/eui";
import MMCell from "components/layouts/table/cell";
import MMMemoryTable from "components/layouts/table/memory-table";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";

export interface MMContactMomentInfoProps {
  client: Client | null;
  contactMoments: any[];
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMContactMomentInfo(props: MMContactMomentInfoProps) {
  const [client, setClient] = useState<Client>();
  const [contactMoments, setContactMoments] = useState<any[]>([]);

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
    if (props.contactMoments) {
      setContactMoments(props.contactMoments);
    } else {
      setContactMoments([]);
    }
  }, [props.contactMoments, props.client, props.isHeaderShown]);

  const getColumns = (): Array<EuiBasicTableColumn<any>> => [
    {
      name: "",
      field: "omschrijving",
      truncateText: { lines: 2 },
      render: (note: string) => <MMCell text={note} size="xs" />,
    },
    {
      name: "",
      field: "contactpersoon_extern",
      width: "80px",
      render: (contact_person_name: string, contactMoment: any) => (
        <MMCell
          align="center"
          size="xs"
          text={
            contact_person_name
              ? contact_person_name
              : contactMoment.behandelaar
                ? contactMoment.behandelaar
                : "-"
          }
          subText={
            contactMoment.datum
              ? DateHelper.toDate(contactMoment.Datum)
              : undefined
          }
        />
      ),
    },
  ];
  return client ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem>
        <EuiFlexGroup justifyContent="spaceBetween">
          <MMTitle
            text={txt.get("clients.communication_info.contact_moments.name")}
          />
          <EuiButtonIcon aria-label="add" iconType={"plus"} />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <MMMemoryTable items={contactMoments} columns={getColumns()} />
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMContactMomentInfo;
