import env from "helpers/env-helper";
import APIHelper, {
  ApiResponse,
  ApiResponseStatus,
  ErrorCode,
} from "./api-helper";

import AuthenticationHelper from "helpers/authentication-helper";
import StringHelper from "helpers/string-helper";
import { versionInfo } from "helpers/version-helper";
import {
  APPOINTMENT_SORTABLE_FIELDS,
  CLIENT_SORTABLE_FIELDS,
} from "./clients-api-field-options";

class ClientsAPIHelper extends APIHelper {
  private ACCESS_TOKEN_KEY = "cloud.manometric.api.clients.access_token";
  private API_ROOT = env("REACT_APP_API_CLIENTS");

  //add auditing info in headers
  getHeaders() {
    let headers = super.getHeaders();
    let auditInfo = {
      deviceInfo: {
        userAgent: navigator.userAgent,
        cookieEnabled: navigator.cookieEnabled,
        language: navigator.language,
        platform: navigator.platform,
      },
      version: versionInfo(),
    };
    headers["MM-Audit-Info"] = StringHelper.toBase64(JSON.stringify(auditInfo));
    return headers;
  }

  getRoot() {
    return this.API_ROOT;
  }

  getAccesstokenKey() {
    return this.ACCESS_TOKEN_KEY;
  }

  getUserCredentialsKey() {
    return null;
  }

  async preHook() {
    //await this.checkAuthentication();
  }

  async postHook() {}

  async checkAuthentication() {
    await AuthenticationHelper.refreshAuthentication();
    this.setAccessToken(AuthenticationHelper.getToken());
  }

  async getPermissions() {
    const path = `permissions`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  appointmentIsSortableBy(key: string) {
    return APPOINTMENT_SORTABLE_FIELDS.includes(key);
  }

  clientIsSortableBy(key: string) {
    return CLIENT_SORTABLE_FIELDS.includes(key);
  }

  async getClientSummaryByClientCodes(
    clientCodes: string[],
    isInsuranceDetailsIncluded: boolean = false
  ) {
    const path: string = "clients";
    let params: any = {
      client_codes: clientCodes,
      fields: [
        "client_code",
        "birth_date",
        "sex",
        "initials",
        "first_name",
        "infix",
        "last_name",
      ],
    };
    if (isInsuranceDetailsIncluded) {
      params.fields.push("client_insurance.insurer_uzovi_code");
      params.fields.push("client_insurance.insurer_name");
    }
    await this.checkAuthentication();

    const result: any = await this.get(path, params);
    return result;
  }
  async getClientSummaryByClientCode(
    clientCode: string,
    isInsuranceDetailsIncluded: boolean = false
  ) {
    return this.getClientSummaryByClientCodes(
      [clientCode],
      isInsuranceDetailsIncluded
    );
  }

  async getClients(
    filters?: any,
    limit?: number,
    offset?: number,
    sortBy?: string,
    sortOrder?: string,
    sinceDate?: Date,
    untilDate?: Date,
    fields?: string[]
  ) {
    const path: string = "clients";
    let params: any = {
      limit,
      offset,
      sortBy,
      sortOrder,
      sinceDate,
      untilDate,
      ...filters,
    };
    if (fields) {
      params.fields = fields;
    }
    await this.checkAuthentication();

    const result: any = await this.get(path, params);
    return result;
  }

  async getClient(clientId: number, fields?: string[]) {
    const path: string = `clients/${clientId}`;
    let params: any = {};
    if (fields) {
      params.fields = fields;
    }
    await this.checkAuthentication();

    const result: any = await this.get(path, params);
    return result;
  }

  async getAppointments(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    const path: string = "appointments";
    const params: any = {
      limit,
      offset,
      sort,
      order,
      ...filters,
    };

    await this.checkAuthentication();

    const result: any = await this.get(path, params);
    return result;
  }

  async getClientContactMoments(
    filters?: any,
    limit?: number,
    offset?: number,
    sortBy?: string,
    sortOrder?: string
  ) {
    const path: string = `clients/contact-moments`;
    const params: any = {
      limit,
      offset,
      sortBy,
      sortOrder,
      ...filters,
    };

    await this.checkAuthentication();

    const result: any = await this.get(path, params);
    return result;
  }

  async getInsuranceDetails(clientId: number, refresh: boolean = false) {
    const path: string = `clients/${clientId}/insurance-details`;
    await this.checkAuthentication();

    const params: any = {
      refresh,
    };

    // Limit the wait time of this API call to 10.000ms.
    try {
      const result: any = await this.timeoutPromise(
        10000,
        this.get(path, params)
      );

      return result;
    } catch (e) {
      return {
        status: ApiResponseStatus.Error,
        message: "request timed out",
        code: ErrorCode.Error,
        content: null,
      } as ApiResponse;
    }
  }

  handleResult(result: any) {
    if (
      result !== undefined &&
      result.status &&
      result.status === ApiResponseStatus.OK
    ) {
      return result.result || true;
    } else if (result.status === ApiResponseStatus.Error) {
      return { message: result.message };
    } else {
      return null;
    }
  }
}

export default ClientsAPIHelper;
