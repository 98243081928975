import {
  EuiAvatar,
  EuiButton,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiPopover,
  EuiSpacer,
  EuiText,
  EuiToken,
} from "@elastic/eui";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import { Input, ValidationResult } from "helpers/input-helper";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { Client, clientName } from "store/data/client/client";
import { Order } from "store/data/order/order";
import { Organisation } from "store/data/organisation/organisation";
import { FocusState, MMOrderEntryInputProps } from "../order-entry";
import { getOrderInput, OrderInputs } from "../order-inputs";

function MMOrderClient(props: MMOrderEntryInputProps) {
  const organisation: Organisation | null = props.organisation || null;
  const order: Order | undefined | null = props.order;
  const inputs: OrderInputs | undefined = props.inputs;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.focusState) {
      if (props.focusState === FocusState.Client) {
        setIsOpen(true);
      }
      if (props.focusState === FocusState.Validating && props.showValidation) {
        setIsOpen(true);
      }
    }
  }, [props.focusState, props.showValidation]);

  const [fields, setFields] = useState<any>({
    client_code: "",
    external_client_name: "",
    external_client_birth_date: "",
    external_client_postal_code: "",
    external_client_street_number: "",
    external_client_reference: "",
    external_client_email: "",
    external_client_phone_number: "",
  });

  useEffect(() => {
    // console.log("order-client receiving order:", order);
    setFields({
      client_code: order?.client_code,
      external_client_name: order?.external_client_name,
      external_client_birth_date: order?.external_client_birth_date,
      external_client_postal_code: order?.external_client_postal_code,
      external_client_street_number: order?.external_client_street_number,
      external_client_reference: order?.external_client_reference,
      external_client_email: order?.external_client_email,
      external_client_phone_number: order?.external_client_phone_number,
    });
  }, [order]);

  const [client, setClient] = useState<Client | null>(props.client || null);
  useEffect(() => {
    setClient(props.client || null);
  }, [props.client]);

  const onFieldsChanged = (changedFields: any) => {
    console.log("onFieldsChanged", changedFields);
    if (props.onChange) {
      props.onChange({ ...order, ...changedFields });
    } else {
      setFields(changedFields);
    }
  };

  // const sanitizeStartsWithNumber = (value: string): string => {
  //   const regex: RegExp = /^\d+/;
  //   if (!regex.test(value)) {
  //     return "";
  //   }
  //   return value;
  // };
  // const sanitizePhoneNumberCharacters = (value: string): string => {
  //   const regex: RegExp = /(?<!^)\+|[^\d+]+/g;
  //   const result = value.replaceAll(/[^\d+]/g, "");
  //   console.log(result);
  //   return result;
  // };

  const clientSummary = (fields: any) => {
    if (client) {
      return (
        <EuiText>
          {client.client_code}
          <br />
          {clientName(client, true)}
        </EuiText>
      );
    } else {
      if (fields.external_client_name) {
        return fields.external_client_name;
      }
      if (AuthenticationHelper.isInternalUser()) {
        if (fields.client_code) {
          return fields.client_code;
        }
      } else {
        if (fields.external_client_reference) {
          return fields.external_client_reference;
        }
      }
    }

    return txt.get("orders.order.add_client_info");
  };

  const renderField = (
    field: string,
    value: string,
    label?: string,
    placeholder?: string,
    flexProps?: any,
    onEnterKey?: any
  ) => {
    const input: Input | null = getOrderInput(field, inputs);

    if (!input) {
      return <></>;
    }

    const validationResult: ValidationResult | null = input.validator
      ? input.validator(value)
      : null;

    const isVisible: boolean = input.visible;

    if (!isVisible) {
      return <></>;
    }

    const isEnabled: boolean = props.isEditable;
    const isValid: boolean =
      props.hasValidation && validationResult ? validationResult.result : true;
    const message: string | null =
      props.hasValidation && validationResult && validationResult.message
        ? validationResult.message
        : null;

    const formLabel: string = label ? label : txt.get(`orders.order.${field}`);
    const formPlaceholder: string = placeholder ? placeholder : formLabel;

    // console.log("renderfield", field, value);
    return (
      <EuiFlexItem {...flexProps}>
        <EuiFormRow
          label={formLabel}
          fullWidth={true}
          isInvalid={!isValid}
          error={message}
          display="rowCompressed"
          isDisabled={!isEnabled}
          className={!isEnabled ? "readonly-input" : ""}
        >
          <EuiFieldText
            compressed={true}
            disabled={!isEnabled}
            readOnly={!isEnabled}
            value={value}
            placeholder={formPlaceholder}
            fullWidth={true}
            onChange={(e: any) =>
              setFields(updateField(fields, field, e.target.value))
            }
            onBlur={(e: any) =>
              onFieldsChanged(
                updateField(
                  fields,
                  field,
                  e.target.value ? e.target.value.trim() : ""
                )
              )
            }
            // onKeyUp={(e: any) => {
            //   if (e.key === "Enter" && onEnterKey) {
            //     console.log(e.target.value);
            //     onFieldsChanged(updateField(fields, field, e.target.value));
            //     // onEnterKey();
            //   }
            // }}
            isInvalid={!isValid}
          />
        </EuiFormRow>
        <EuiSpacer size="s" />
      </EuiFlexItem>
    );
  };

  const close = () => {
    setIsOpen(false);
    onFieldsChanged(fields);
    if (
      props.focusState &&
      props.focusState === FocusState.Client &&
      props.changeFocusState
    ) {
      props.changeFocusState(FocusState.Order);
    }
  };

  return props.focusState && props.focusState === FocusState.Loading ? (
    <></>
  ) : isOpen && props.focusState && props.focusState === FocusState.Client ? (
    <EuiModal
      className="order-client-modal"
      onClose={() => {
        close();
      }}
    >
      <EuiModalBody>
        <EuiFlexGroup
          className={`client-info client-focus-${
            props.focusState ? props.focusState.toLowerCase() : ""
          }`}
          gutterSize="s"
          direction="column"
        >
          {renderField(
            "external_client_name",
            fields.external_client_name,
            txt.get("orders.order.client_name")
          )}

          {renderField(
            "external_client_birth_date",
            fields.external_client_birth_date,
            txt.get("orders.order.client_birth_date"),
            txt.get("orders.order.client_birth_date_format")
          )}

          <EuiFlexItem>
            <EuiFlexGroup>
              {renderField(
                "external_client_postal_code",
                fields.external_client_postal_code,
                txt.get("orders.order.client_postal_code"),
                txt.get("orders.order.client_postal_code_format"),
                { grow: 7 }
              )}
              {renderField(
                "external_client_street_number",
                fields.external_client_street_number,
                txt.get("orders.order.client_street_number"),
                txt.get("orders.order.client_street_number_format"),
                { grow: 3 }
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
          {renderField(
            "external_client_reference",
            fields.external_client_reference,
            txt.get(
              `${
                organisation && organisation.client_reference
                  ? organisation.client_reference
                  : "orders.external_client_reference_name.patient_number"
              }`
            )
          )}
          {renderField(
            "client_code",
            fields.client_code,
            undefined,
            undefined,
            null,
            props.focusState && props.focusState === FocusState.Client
              ? () => {
                  close();
                }
              : undefined
          )}

          {getOrderInput("external_client_email", inputs) ||
          getOrderInput("external_client_phone_number", inputs) ? (
            <Fragment>
              {renderField(
                "external_client_email",
                fields.external_client_email,
                txt.get("orders.order.client_email")
              )}
              {renderField(
                "external_client_phone_number",
                fields.external_client_phone_number,
                txt.get("orders.order.client_phone_number")
              )}
            </Fragment>
          ) : (
            <></>
          )}
          {client ? (
            <EuiFlexGroup>
              <EuiFlexItem
                grow={false}
                style={{ margin: "0px", padding: "0px 4px 10px" }}
              >
                <EuiText size="xs" color="subdued">
                  <strong>{txt.get("clients.vlot_info")}</strong>
                  <br />
                  {clientName(client, true)}
                  <br />
                  {DateHelper.toDate(client.birth_date)}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : (
            <></>
          )}
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiFlexItem grow={false}>
              <EuiButton
                size="s"
                color="accent"
                fill={true}
                onClick={(e: any) => close()}
                data-testid="client-next-button"
              >
                {txt.get("generic.next")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexGroup>
      </EuiModalBody>
    </EuiModal>
  ) : (
    <EuiPopover
      panelPaddingSize="l"
      isOpen={isOpen}
      closePopover={() => {
        close();
      }}
      button={
        props.focusState &&
        [FocusState.Client, FocusState.Loading].includes(props.focusState) ? (
          <EuiFlexItem className="client-focus-holder"></EuiFlexItem>
        ) : (
          <EuiFlexGroup gutterSize="xs" alignItems="center">
            <EuiFlexItem style={{ width: "44px", position: "relative" }}>
              {fields.external_client_name ? (
                <EuiAvatar
                  color="#e9ebf0"
                  size="m"
                  name={fields.external_client_name}
                ></EuiAvatar>
              ) : (
                <EuiAvatar
                  color="#e9ebf0"
                  iconType="user"
                  size="m"
                  name={fields.external_client_reference || ""}
                ></EuiAvatar>
              )}
              {client && client.client_code ? (
                <EuiToken
                  style={{ position: "absolute", top: "-7px", left: "-5px" }}
                  iconType="check"
                  size="s"
                  shape="circle"
                  color="#b9f6c8"
                />
              ) : (
                <></>
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGrid
                gutterSize="none"
                className="hoverable"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <EuiFlexGroup gutterSize="s">
                  <EuiText
                    aria-placeholder={txt.get(
                      "orders.order.client_summary_placeholder"
                    )}
                    color={props.hasValidation ? "danger" : undefined}
                  >
                    {clientSummary(fields)}
                  </EuiText>
                  <EuiIcon
                    size="s"
                    type={props.isEditable ? "pencil" : "eye"}
                    color="#ffffff"
                    className="hoverable-highlight"
                  />
                </EuiFlexGroup>
                <EuiText style={{ opacity: 0.5 }}>
                  {client && client.birth_date
                    ? DateHelper.toDate(client.birth_date)
                    : fields.external_client_birth_date || ""}
                </EuiText>
              </EuiFlexGrid>
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    >
      <EuiFlexGroup
        className={`client-info client-focus-${
          props.focusState ? props.focusState.toLowerCase() : ""
        }`}
        gutterSize="s"
        direction="column"
      >
        {renderField(
          "external_client_name",
          fields.external_client_name,
          txt.get("orders.order.client_name")
        )}

        {renderField(
          "external_client_birth_date",
          fields.external_client_birth_date,
          txt.get("orders.order.client_birth_date"),
          txt.get("orders.order.client_birth_date_format")
        )}

        <EuiFlexItem>
          <EuiFlexGroup>
            {renderField(
              "external_client_postal_code",
              fields.external_client_postal_code,
              txt.get("orders.order.client_postal_code"),
              txt.get("orders.order.client_postal_code_format"),
              { grow: 7 }
            )}
            {renderField(
              "external_client_street_number",
              fields.external_client_street_number,
              txt.get("orders.order.client_street_number"),
              txt.get("orders.order.client_street_number_format"),
              { grow: 3 }
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        {renderField(
          "external_client_reference",
          fields.external_client_reference,
          txt.get(
            `${
              organisation && organisation.client_reference
                ? organisation.client_reference
                : "orders.external_client_reference_name.patient_number"
            }`
          )
        )}
        {renderField(
          "client_code",
          fields.client_code,
          undefined,
          undefined,
          null,
          null
        )}

        {getOrderInput("external_client_email", inputs) ||
        getOrderInput("external_client_phone_number", inputs) ? (
          <Fragment>
            {renderField(
              "external_client_email",
              fields.external_client_email,
              txt.get("orders.order.client_email")
            )}
            {renderField(
              "external_client_phone_number",
              fields.external_client_phone_number,
              txt.get("orders.order.client_phone_number")
            )}
          </Fragment>
        ) : (
          <></>
        )}
        {client ? (
          <EuiFlexGroup>
            <EuiFlexItem
              grow={false}
              style={{ margin: "0px", padding: "0px 4px 10px" }}
            >
              <EuiText size="xs" color="subdued">
                <strong>{txt.get("clients.vlot_info")}</strong>
                <br />
                {clientName(client, true)}
                <br />
                {DateHelper.toDate(client.birth_date)}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <></>
        )}
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton
              size="s"
              color="accent"
              fill={true}
              onClick={(e: any) => close()}
            >
              {txt.get("generic.ok")}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>
    </EuiPopover>
  );
}

export default MMOrderClient;
